<template>
  <v-expansion-panel id="export-domains">
    <v-expansion-panel-header class="panel-header-text-color">
      <strong>{{ headerText }}</strong>
      <template v-slot:actions>
        <v-icon>$expand</v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      {{ contentText }}
      <br />
      <slot name="downloadLinks"></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'DownloadPanel',
  props: {
    headerText: {
      type: String,
      required: true
    },
    contentText: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~@/sass/variables'
.panel-header-text-color
  strong, * > .v-icon
    color: $secondary !important
</style>
