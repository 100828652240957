<template>
  <div class="export-link px-1">
    <a v-if="this.linkAsButton === false" class="download-link" :href="href" @click.prevent="refreshTokenAndOpenUrl(href)">{{ this.linkText }}</a>
    <v-btn v-else icon :href="href" @click.prevent="refreshTokenAndOpenUrl(href)" class="download-link data-export-btn">
      <v-icon>mdi-download-outline</v-icon>
    </v-btn>
  </div>
</template>

<script>
import i18n from '../../i18n'

export default {
  props: {
    url: {
      String,
      default: '',
      required: true
    },
    linkText: {
      String,
      default: i18n.t('misc.download_file')
    },
    linkAsButton: {
      Boolean,
      default: false
    },
    fileName: {
      String,
      default: 'download'
    }
  },
  computed: {
    href() {
      return process.env.VUE_APP_API_BASE_URL + this.url
    }
  },
  methods: {
    refreshTokenAndOpenUrl(href) {
      this.$store.dispatch('auth/forceTokenRefreshWhenRequired').then(() => {
        window.open(href, '_blank')
      })
    }
  }
}
</script>
