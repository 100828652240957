<template>
  <div id="stats-exports">
    <Headline />
    <StatsExport />
  </div>
</template>

<script>
import Headline from '../../components/app/Headline'
import StatsExport from '../../components/stats/StatsExport'

export default {
  components: {
    Headline,
    StatsExport
  }
}
</script>
