<template>
  <div id="exports">
    <v-row>
      <v-col class="col-12">
        <p>{{ $t('stats-exports.intro_text') }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <v-expansion-panels accordion>
          <DownloadPanel
            id="export-domains"
            :header-text="$t('stats-exports.domains_heading').toString()"
            :content-text="$t('stats-exports.domains_details').toString()"
          >
            <template v-slot:downloadLinks>
              <DownloadLink
                url="/ui/domains?format=csv&export=1&download=1"
                :link-text="$t('stats-exports.domains_label')"
                file-name="domains.csv"
                :link-as-button="false"
              />
            </template>
          </DownloadPanel>
          <DownloadPanel
            id="export-folders"
            :header-text="$t('stats-exports.folders_heading').toString()"
            :content-text="$t('stats-exports.folders_details').toString()"
          >
            <template v-slot:downloadLinks>
              <DownloadLink
                url="/ui/folders?format=csv&export=1&download=1"
                :link-text="$t('stats-exports.folders_label')"
                file-name="folders.csv"
                :link-as-button="false"
              />
            </template>
          </DownloadPanel>
          <DownloadPanel
            id="export-daily-domain-stats"
            :header-text="$t('stats-exports.stats_daily_heading').toString()"
            :content-text="$t('stats-exports.stats_daily_details', { months: months }).toString()"
          >
            <template v-slot:downloadLinks>
              <ul v-for="slice in getMonthlySlices" :key="slice.label">
                <li>
                  <DownloadLink
                    :url="'/ui/stats/domain/date?from=' + slice.start + '&to=' + slice.end + '&format=csv&export=1&download=1'"
                    :link-text="$t('stats-exports.stats_daily_label') + ' ' + slice.label"
                    :file-name="'daily_domains_stats_' + slice.file_suffix + '.csv'"
                    :link-as-button="false"
                  />
                </li>
              </ul>
            </template>
          </DownloadPanel>
          <DownloadPanel
            id="export-monthly-domain-stats"
            :header-text="$t('stats-exports.stats_monthly_heading').toString()"
            :content-text="$t('stats-exports.stats_monthly_details', { months: months }).toString()"
          >
            <template v-slot:downloadLinks>
              <ul v-for="slice in getMonthlySlices" :key="slice.label">
                <li>
                  <DownloadLink
                    :url="'/ui/stats/domain/month?from=' + slice.start + '&to=' + slice.start + '&format=csv&export=1&download=1'"
                    :link-text="$t('stats-exports.stats_monthly_label') + ' ' + slice.label"
                    :file-name="'monthly_domains_stats_' + slice.file_suffix + '.csv'"
                    :link-as-button="false"
                  />
                </li>
              </ul>
            </template>
          </DownloadPanel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DownloadLink from '@/components/others/DownloadLink'
import DateHelper from '@/util/DateHelper'
import DownloadPanel from '@/components/others/DownloadPanel.vue'

export default {
  components: {
    DownloadPanel,
    DownloadLink
  },
  props: {
    force_months: {
      type: Number
    }
  },
  computed: {
    getMonthlySlices() {
      return DateHelper.getMonthlySlices(DateHelper.today(), this.months)
    },
    months() {
      if (this.force_months) {
        return this.force_months
      }
      return this.$store.getters['user/getStatsMonthsToExport'](DateHelper.today())
    }
  }
}
</script>
